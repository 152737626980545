const NATIVE_AD_COUNT = 10;
const NATIVE_AD_UNIT_ID = CAFEAPP.IS_DEV === 'true' ? 'DAN-GpEMaLrwSldqlzhd' : 'DAN-5vND7uvRg45up0we';

function nativeAdTemplate(unitId) {
	return `
		<li adfit-unit-id="${unitId}">
			<a class="popular-list__link" adfit-landing-url adfit‐target‐self>
				<div class="popular-list__thumb-image">
					<img width="90" height="90" adfit-image-width="90" adfit-image-height="90" adfit-main-img-url>
				</div>
				<div class="popular-list__content">
					<span class="popular-list__ad-icon">
						<span class="sr_only">AD</span>
					</span>
					<strong class="popular-list__title" adfit-title></strong>
					<span class="popular-list__cafe-name" adfit-profile-name></span>
				</div>
			</a>
		</li>
	`;
}

function initNativeAd(unitId, callback) {
	const html = nativeAdTemplate(unitId).repeat(NATIVE_AD_COUNT);
	new AdfitNativeAds({unitId: unitId}).renderAs(html, callback).init();
}

initNativeAd(NATIVE_AD_UNIT_ID, ads => {
	const wrapper = document.querySelector('.popular-list');
	const placeholders = Array.from(wrapper.querySelectorAll('.nativead-placeholder'));

	ads.forEach(ad => {
		const placeholder = placeholders.shift();

		if (!placeholder) {
			return;
		}

		wrapper.insertBefore(ad, placeholder);
		placeholder.remove();
	});

	// 광고가 요청한 개수보다 적게 내려오는 경우 남아있는 placeholder 제거
	placeholders.forEach(placeholder => placeholder.remove());
});
